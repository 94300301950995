//fonts
$fonts_path: '../fonts/';
$fontsize: 16; //base font size, used by default on em calculations
$fontweight: 400;
$line_height: 1;
$fontf1: sans-serif;

//images
$images_path: '../images/';

//colors
$c_base: #222;
$c_err: #f44;
$c_st_err: #e00;
$c_st_ok: #090;
$c_st_warn: $c_base;

//bgs
$bg_page: #f8f8f8;
$bg_base: #fff;
$bg_puzzle: #fff;
$bg_popup: #000;
$bg_popup_trans: 0.3;
$bg_stripe1: #f6f6f6;
$bg_stripe2: #eee;
$bg_sel: #ccc;
$bg_dz_hover: #00f;

//borders
$b_puzzle: #aaa;
$b_table: #cfcfcf;
$b_img: #aaa;
$b_sep: #ccc;

//sizes
$canvas_size_w_min: 800;

//export
:export {
  // scss-lint:disable PropertySpelling
  canvas_size_w_min: $canvas_size_w_min;
}
