:export {
  canvas_size_w_min: 800;
}

/* css reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* end of css reset */
/* fonts */
/* general */
body {
  background-color: #f8f8f8;
  color: #222;
  font: normal 400 16px/1 sans-serif;
}

.clear {
  clear: both;
}

.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.hidden {
  display: none !important;
}

input, textarea, button, select {
  font-family: inherit;
  outline: none;
}
input::-ms-clear, textarea::-ms-clear, button::-ms-clear, select::-ms-clear {
  display: none;
}

a {
  color: inherit;
  text-decoration: underline;
}

/* noscript */
.noscript_h1 {
  color: #f44;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.25rem;
  text-align: center;
}

/* main */
.main_wrap {
  text-align: center;
}

.main_cont {
  display: inline-block;
  padding: 0.3125rem;
  text-align: left;
  vertical-align: bottom;
}
.main_cont:before {
  content: " ";
  display: table;
}
.main_cont:after {
  clear: both;
  content: " ";
  display: table;
}

/* controls */
.controls_wrap {
  border-radius: 0.25rem;
  box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.3);
  float: left;
  padding: 0 0.3125rem 0.3125rem 0.3125rem;
  width: 12.5rem;
}
.controls_wrap:disabled label.control_cont {
  cursor: auto;
  cursor: not-allowed;
}

.control_sep {
  font-size: 0.75rem;
  min-height: 0.75rem;
  padding: 0.3125rem 0 0.125rem 0;
  position: relative;
  text-align: center;
}
.control_sep:before {
  border-bottom: 1px solid #ccc;
  content: " ";
  display: block;
  left: 0;
  margin-top: 0.09375rem;
  position: absolute;
  right: 0;
  top: 50%;
}
.control_sep .text {
  background-color: #f8f8f8;
  max-width: 100%;
  padding: 0 0.1875rem;
  position: relative;
  text-align: center;
}

.control_same_sep {
  display: block;
  height: 0.25rem;
  width: 100%;
}

.control_double_cont {
  display: flex;
  flex-wrap: wrap;
}
.control_double_cont .control_cont {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
  width: 50%;
}

.control_cont {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.25rem 0 0.1875rem 0;
}
.control_cont .control_lbl {
  display: block;
  font-size: 0.8125rem;
  margin-right: 0.3125rem;
}
.control_cont .control_lbl.spiece_name {
  word-break: break-word;
}
.control_cont .control_lblc {
  margin-right: 0;
  text-align: center;
  width: 100%;
}
.control_cont .control_lbl_warn {
  color: #222;
  font-size: 0.625rem;
  margin: 0.25rem 5% 0 5%;
}
.control_cont .control_btn,
.control_cont .control_sel,
.control_cont .control_file,
.control_cont .control_inp {
  cursor: pointer;
}
.control_cont .control_btn:disabled,
.control_cont .control_sel:disabled,
.control_cont .control_file:disabled,
.control_cont .control_inp:disabled {
  cursor: auto;
  cursor: not-allowed;
}
.control_cont .control_btn {
  min-width: 1.625rem;
}
.control_cont .local_storage_supported .lss_text {
  display: none;
}
.control_cont .local_storage_supported .no_lss_text {
  display: inline;
}
.control_cont .local_storage_supported.lss_enabled .lss_text {
  display: inline;
}
.control_cont .local_storage_supported.lss_enabled .no_lss_text {
  display: none;
}
.control_cont .control_color, .control_cont .sp-replacer {
  width: 2.75rem;
}
.control_cont .control_range {
  width: 6.25rem;
}
.control_cont .control_sel {
  min-width: 3.125rem;
}
.control_cont .control_file {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.control_cont .dz-clickable.dz-drag-hover {
  box-shadow: 0 0 0.25rem 0 #00f;
}
.control_cont .control_inp {
  margin: 0 0.1875rem;
}
.control_cont .control_btns.full_width {
  text-align: center;
  width: 100%;
}
.control_cont .control_btns .control_btn + .control_btn {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_btn + .control_sel {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_btn + .control_color {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_btn + .my_controls {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_sel + .control_btn {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_sel + .control_sel {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_sel + .control_color {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_sel + .my_controls {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_color + .control_btn {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_color + .control_sel {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_color + .control_color {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .control_color + .my_controls {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .my_controls + .control_btn {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .my_controls + .control_sel {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .my_controls + .control_color {
  margin-left: 0.3125rem;
}
.control_cont .control_btns .my_controls + .my_controls {
  margin-left: 0.3125rem;
}
.control_cont .control_canvas_radio_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.1875rem 0 0 0;
  width: 100%;
}
.control_cont .control_canvas_radio_cont .control_cont {
  margin: 0 0.125rem;
  padding: 0.1875rem 0.125rem;
}
.control_cont .control_canvas_radio_cont .control_lbl {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

label.control_cont {
  cursor: pointer;
}

.control_contc {
  justify-content: center;
}

.sp-replacer.my_controls {
  height: 1.0625rem;
  padding: 0.125rem;
}
.sp-replacer.my_controls.sp-disabled {
  cursor: auto;
  cursor: not-allowed;
}
.sp-replacer.my_controls .sp-preview {
  height: 0.9375rem;
}
.sp-replacer.my_popup {
  height: 1.0625rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  padding: 0.125rem;
}
.sp-replacer.my_popup .sp-preview {
  height: 0.9375rem;
}

.sp-container.ok_is_close .sp-cancel {
  display: none;
}

.control_status {
  font-size: 0.75rem;
  min-height: 1em;
  padding: 0 0.5rem;
  text-align: center;
  width: 100%;
}
.control_status.green {
  color: #090;
}
.control_status.red {
  color: #e00;
}
.control_status .green {
  color: #090;
}
.control_status .red {
  color: #e00;
}
.control_status .base {
  color: #222;
}

/* piece_load_popup */
.popup_wrap {
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  font-size: 0.8125rem;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.popup_wrap.show {
  display: block;
}
.popup_wrap .popup_cont {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 92%;
  overflow: auto;
  padding: 0.625rem 1rem 1rem 1rem;
  position: absolute;
  top: 4%;
  transform: translateX(-50%);
}
.popup_wrap .popup_cont.piece_zoomed_in {
  padding: 0;
  position: static;
  transform: none;
  visibility: hidden;
}
.popup_wrap .popup_cont.piece_zoomed_in .piece_cont {
  display: none;
}
.popup_wrap .title {
  font-size: 0.875rem;
  padding: 0.25rem 0;
}
.popup_wrap .control_sep {
  min-height: 0.6875rem;
  padding-bottom: 0.0625rem;
  padding-top: 0.0625rem;
}
.popup_wrap .control_sep:before {
  margin-top: 0;
}
.popup_wrap .control_cont {
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}
.popup_wrap .control_cont .control_btn + .control_btn {
  margin-left: 0.3125rem;
}
.popup_wrap .control_btn {
  font-size: 1em;
}
.popup_wrap .pieces_wrap {
  flex-grow: 1;
  min-height: 7.5rem;
  overflow: auto;
}
.popup_wrap .pieces_wrap .piece_cont {
  display: flex;
  padding: 0.125rem 0.375rem;
}
.popup_wrap .pieces_wrap .piece_cont:nth-child(odd) {
  background-color: #eee;
}
.popup_wrap .pieces_wrap .piece_cont:nth-child(even) {
  background-color: #f6f6f6;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in {
  box-sizing: border-box;
  display: flex;
  left: auto;
  max-height: 92%;
  max-width: 90%;
  padding: 1.25rem;
  position: fixed;
  top: 4%;
  visibility: visible;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_img_wrap {
  order: 5;
  padding-left: 0.375rem;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_img_cb {
  display: none;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_img_cont {
  height: auto;
  width: auto;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_img_cont img {
  display: block;
  left: auto;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: auto;
  transform: none;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_zoom_cont {
  order: 0;
  padding-left: 0;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_zoom_cont .piece_zoom:after {
  content: "Back";
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .piece_name_cont {
  order: 10;
}
.popup_wrap .pieces_wrap .piece_cont.piece_zoomed_in .transp_colors {
  order: 15;
}
.popup_wrap .pieces_wrap .piece_img_cb {
  cursor: pointer;
  margin: 0.625rem 0.625rem 0.625rem 0.25rem;
}
.popup_wrap .pieces_wrap .piece_img_wrap {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-right: 0.375rem;
}
.popup_wrap .pieces_wrap .piece_img_cont {
  display: block;
  height: 7.5rem;
  position: relative;
  width: 7.5rem;
}
.popup_wrap .pieces_wrap .piece_img_cont img {
  border: 1px solid #aaa;
  display: block;
  height: auto;
  left: 50%;
  max-height: 7.5rem;
  max-width: 7.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}
.popup_wrap .pieces_wrap .piece_zoom_cont {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.popup_wrap .pieces_wrap .piece_zoom_cont .piece_zoom:after {
  content: "Zoom";
}
.popup_wrap .pieces_wrap .piece_name_cont {
  flex-direction: column;
  justify-content: center;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.popup_wrap .pieces_wrap .piece_name_cont .piece_name {
  margin-top: 0.25rem;
  max-width: 7em;
  text-align: center;
}
.popup_wrap .pieces_wrap .transp_colors {
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
  width: 13.75rem;
}
.popup_wrap .pieces_wrap .transp_colors .transp_color {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.popup_wrap .pieces_wrap .transp_colors .transp_color > * {
  visibility: hidden;
}
.popup_wrap .pieces_wrap .transp_colors .transp_color.enabled > * {
  visibility: visible;
}
.popup_wrap .pieces_wrap .transp_colors .transp_color label {
  cursor: pointer;
  display: block;
  text-align: center;
  visibility: inherit;
}
.popup_wrap .pieces_wrap .transp_colors .piece_trans_cb {
  cursor: pointer;
  margin: 0.25rem 0.625rem 0 0.25rem;
}
.popup_wrap .pieces_wrap .transp_colors .control_color {
  height: 1.3125rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
}
.popup_wrap .pieces_wrap .transp_colors .control_range {
  margin-bottom: 0;
  margin-top: 0.25rem;
  width: 3.75rem;
}
.popup_wrap .oload_wrap,
.popup_wrap .oload_loading,
.popup_wrap .oload_list_cont,
.popup_wrap .oload_key {
  margin: 0 auto;
  max-width: 31.25rem;
  min-width: 27.5rem;
  text-align: center;
}
.popup_wrap .oload_list_note {
  font-size: 0.625rem;
  max-width: 27.5rem;
  padding: 0.375rem 0;
  text-align: left;
}
.popup_wrap .oload_osave_loading_img {
  display: block;
  margin: 0.5rem auto 0 auto;
}
.popup_wrap .oload_loading {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 5.5rem;
  padding: 0.625rem 0;
}
.popup_wrap .oload_loading .oload_loading_in {
  text-align: center;
  width: 100%;
}
.popup_wrap .oload_list_cont {
  flex-grow: 1;
  min-height: 6.25rem;
  overflow: auto;
  padding: 0.5rem 0 0 0;
}
.popup_wrap .oload_list_cont .oload_list_name {
  font-size: 0.75rem;
  max-width: 27.5rem;
}
.popup_wrap .oload_list_cont .oload_list_out {
  max-height: 18.75rem;
  overflow: auto;
  padding: 0.125rem 0;
  width: 100%;
}
.popup_wrap .oload_list_cont .oload_list {
  border: 1px solid #cfcfcf;
  margin: 0 auto;
  width: 100%;
}
.popup_wrap .oload_list_cont .oload_list td, .popup_wrap .oload_list_cont .oload_list th {
  padding: 0.375rem 0.25rem;
}
.popup_wrap .oload_list_cont .oload_list thead {
  border-bottom: 1px solid #cfcfcf;
}
.popup_wrap .oload_list_cont .oload_list tbody tr:nth-child(odd) {
  background-color: #eee;
}
.popup_wrap .oload_list_cont .oload_list tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}
.popup_wrap .oload_list_cont .oload_list tbody tr.sel {
  background-color: #ccc;
}
.popup_wrap .oload_list_cont .oload_list td.date {
  width: 12em;
}
.popup_wrap .oload_list_cont .oload_list tr.removing .link_cont {
  background: transparent url("../images/loading24.gif") center center no-repeat;
}
.popup_wrap .oload_list_cont .oload_list tr.removing .link {
  visibility: hidden;
}
.popup_wrap .oload_osave_key {
  font-size: 0.6875rem;
  margin: 0 auto;
  max-width: 25rem;
  min-width: 0;
  padding: 0.375rem 0 0 0;
}
.popup_wrap .oload_osave_key .key_input,
.popup_wrap .oload_osave_key .key_btn {
  font-size: 1em;
  text-align: center;
}
.popup_wrap .oload_osave_key .key_input:disabled,
.popup_wrap .oload_osave_key .key_btn:disabled {
  cursor: auto;
  cursor: not-allowed;
}
.popup_wrap .oload_osave_key .key_btn {
  cursor: pointer;
}
.popup_wrap .oload_osave_key .oload_load_key_cont {
  text-align: center;
}
.popup_wrap .oload_osave_key .key_status {
  font-size: 0.6875rem;
  max-width: 100%;
  min-height: 1em;
  padding: 0.3125rem 0 0.1875rem 0;
  text-align: center;
}
.popup_wrap .oload_osave_key .key_status.green {
  color: #090;
}
.popup_wrap .oload_osave_key .key_status.red {
  color: #e00;
}
.popup_wrap .osave_wrap {
  align-items: start;
}
.popup_wrap .osave_cont {
  padding: 0 0.375rem 0 0;
  text-align: left;
}
.popup_wrap .osave_cont .save_input {
  font-size: 1em;
  text-align: center;
}
.popup_wrap .osave_cont .save_input:disabled {
  cursor: auto;
  cursor: not-allowed;
}
.popup_wrap .osave_cont .name_status {
  font-size: 0.6875rem;
  max-width: 100%;
  min-height: 1em;
  padding: 0.3125rem 0 0.1875rem 0;
  text-align: center;
}
.popup_wrap .osave_cont .name_status.green {
  color: #090;
}
.popup_wrap .osave_cont .name_status.red {
  color: #e00;
}
.popup_wrap .osave_name_info {
  font-size: 0.6875rem;
  max-width: 27.5rem;
  padding: 0.3125rem 0;
}

/* right side */
.right_side_cont {
  margin: 0 0 0 13.125rem;
  padding: 0 0 0 0.5rem;
}

/* canvas_links_wrap */
.canvas_links_wrap {
  font-size: 0.6875rem;
  overflow: auto;
  max-width: 100%;
  padding: 0 0 0.25rem 0;
}
.canvas_links_wrap .title {
  padding: 0 0 0.125rem 0;
}
.canvas_links_wrap .canvas_links_cont {
  padding: 0.125rem 0 0 0;
  white-space: nowrap;
}
.canvas_links_wrap .link {
  border: 1px solid #cfcfcf;
  display: inline-block;
  margin: 0 0.25rem 0 0;
  text-align: center;
  vertical-align: top;
  width: 5rem;
  white-space: normal;
}
.canvas_links_wrap .link a {
  display: block;
  min-height: 5.125rem;
}
.canvas_links_wrap .link .img_cont {
  height: 3.75rem;
  margin: 0 0 0.125rem 0;
  padding: 0.125rem;
}
.canvas_links_wrap .link .img_cont_sm {
  height: 2.875rem;
}
.canvas_links_wrap .link img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}
.canvas_links_wrap .link .name {
  display: block;
  margin: auto 0 0 0;
  padding: 0 0 0.125rem 0;
  line-height: 0.875rem;
}

/* canvas */
.canvas_wrap {
  padding: 0.3125rem 0;
}
.canvas_wrap .canvas_cont {
  background-color: #fff;
  border: 1px solid #aaa;
  position: relative;
}
.canvas_wrap .canvas_cont:focus {
  outline: none;
}
.canvas_wrap .canvas_cont.loading:after {
  background: transparent url("../images/loading43.gif") center 3.125rem no-repeat;
  content: " ";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.canvas_wrap canvas {
  display: block;
}

.canvas_bnote,
.canvas_note {
  max-width: 800px;
}
.canvas_bnote > div,
.canvas_note > div {
  padding: 0.125rem 0;
}

.canvas_note {
  font-size: 0.6875rem;
  padding: 0 0 0.25rem 0;
}

.canvas_bnote {
  float: right;
  font-size: 0.6875rem;
  line-height: 1.18181818;
  padding: 0.0625rem 0 0 0;
  text-align: right;
}
.canvas_bnote.left {
  float: left;
  padding-right: 0.5rem;
  text-align: left;
}

.info_expand_wrap .info_expand_open .no_open_text {
  display: inline;
}
.info_expand_wrap .info_expand_open .open_text {
  display: none;
}
.info_expand_wrap .info_expand {
  display: none;
}
.info_expand_wrap.open .info_expand_open .no_open_text {
  display: none;
}
.info_expand_wrap.open .info_expand_open .open_text {
  display: inline;
}
.info_expand_wrap.open .info_expand {
  display: block;
}

.info_cont {
  text-align: left;
}
.info_cont > div {
  padding: 0.125rem 0;
}
.info_cont ul {
  list-style: disc outside;
  padding-left: 1.4em;
}
.info_cont ul li {
  padding: 0.125rem 0 0.0625rem 0;
}
.info_cont .sep {
  padding: 0.25rem 0 0.125rem 0;
}