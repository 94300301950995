/* general */
body {
  background-color: $bg_page;
  color: $c_base;
  font: normal $fontweight #{$fontsize}px/#{$line_height} $fontf1;
}
.clear {
  clear: both;
}
.clearfix {
  @include clearfix();
}
.hidden {
  display: none !important; // scss-lint:disable ImportantRule
}
input, textarea, button, select {
  //color: inherit;
  font-family: inherit;
  outline: none;
  &::-ms-clear {
    display: none;
  }
}
a {
  color: inherit;
  text-decoration: underline;
}