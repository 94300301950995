/* noscript */
.noscript_h1 {
  color: $c_err;
  font-size: rem(20);
  font-weight: 700;
  padding: rem(20);
  text-align: center;
}
/* main */
.main_wrap {
  text-align: center;
}
.main_cont {
  @include clearfix();
  display: inline-block;
  padding: rem(5);
  text-align: left;
  vertical-align: bottom;
}
/* controls */
.controls_wrap {
  border-radius: rem(4);
  box-shadow: 0 0 rem(3) rgba(0,0,0,0.3);
  float: left;
  padding: 0 rem(5) rem(5) rem(5);
  width: rem(200);
  &:disabled {
    label {
      &.control_cont {
        cursor: auto;
        cursor: not-allowed; // scss-lint:disable DuplicateProperty
      }
    }
  }
}
.control_sep {
  $pad_bottom: 2;
  $pad_top: 5;
  $fsize: 12;
  font-size: rem($fsize);
  min-height: rem($fsize);
  padding: rem($pad_top) 0 rem($pad_bottom) 0;
  position: relative;
  text-align: center;
  &:before {
    border-bottom: 1px solid $b_sep;
    content: ' ';
    display: block;
    left: 0;
    margin-top: rem(($pad_top - $pad_bottom) / 2);
    position: absolute;
    right: 0;
    top: 50%;
  }
  .text {
    background-color: $bg_page;
    max-width: 100%;
    padding: 0 rem(3);
    position: relative;
    text-align: center;
  }
}
.control_same_sep {
  display: block;
  height: rem(4);
  width: 100%;
}
.control_double_cont {
  display: flex;
  flex-wrap: wrap;
  .control_cont {
    padding-left: rem(3);
    padding-right: rem(3);
    width: 50%;
  }
}
.control_cont {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: rem(4) 0 rem(3) 0;
  .control_lbl {
    display: block;
    font-size: rem(13);
    margin-right: rem(5);
    &.spiece_name {
      word-break: break-word;
    }
  }
  .control_lblc {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
  .control_lbl_warn {
    color: $c_st_warn;
    font-size: rem(10);
    margin: rem(4) 5% 0 5%;
  }
  .control_btn,
  .control_sel,
  .control_file,
  .control_inp {
    cursor: pointer;
    &:disabled {
      cursor: auto;
      cursor: not-allowed; // scss-lint:disable DuplicateProperty
    }
  }
  .control_btn {
    min-width: rem(26);
  }
  .local_storage_supported {
    .lss_text {
      display: none;
    }
    .no_lss_text {
      display: inline;
    }
    &.lss_enabled {
      .lss_text {
        display: inline;
      }
      .no_lss_text {
        display: none;
      }
    }
  }
  .control_color, .sp-replacer { // scss-lint:disable SelectorFormat
    width: rem(44);
  }
  .control_range {
    width: rem(100);
  }
  .control_sel {
    min-width: rem(50);
  }
  .control_file {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .dz-clickable { // scss-lint:disable SelectorFormat
    &.dz-drag-hover { // scss-lint:disable SelectorFormat
      box-shadow: 0 0 rem(4) 0 $bg_dz_hover;
    }
  }
  .control_inp {
    margin: 0 rem(3);
  }
  .control_btns {
    &.full_width {
      text-align: center;
      width: 100%;
    }
    $inp_list: '.control_btn', '.control_sel', '.control_color', '.my_controls';
    @each $inp1 in $inp_list {
      @each $inp2 in $inp_list {
        #{$inp1} + #{$inp2} {
          margin-left: rem(5);
        }
      }
    }
  }
  .control_canvas_radio_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: rem(3) 0 0 0;
    width: 100%;
    .control_cont {
      margin: 0 rem(2);
      padding: rem(3) rem(2);
    }
    .control_lbl {
      margin-left: rem(2);
      margin-right: rem(2);
    }
  }
}
label {
  &.control_cont {
    cursor: pointer;
  }
}
.control_contc {
  justify-content: center;
}

.sp-replacer { // scss-lint:disable SelectorFormat
  &.my_controls {
    height: rem(17);
    padding: rem(2);
    &.sp-disabled { // scss-lint:disable SelectorFormat
      cursor: auto;
      cursor: not-allowed; // scss-lint:disable DuplicateProperty
    }
    .sp-preview { // scss-lint:disable SelectorFormat
      height: rem(15);
    }
  }
  &.my_popup {
    height: rem(17);
    margin-bottom: rem(12);
    margin-top: rem(4);
    padding: rem(2);
    .sp-preview { // scss-lint:disable SelectorFormat
      height: rem(15);
    }
  }
}
.sp-container.ok_is_close { // scss-lint:disable SelectorFormat
  .sp-cancel { // scss-lint:disable SelectorFormat
    display: none;
  }
}

.control_status {
  font-size: rem(12);
  min-height: 1em;
  padding: 0 rem(8);
  text-align: center;
  width: 100%;
  &.green {
    color: $c_st_ok;
  }
  &.red {
    color: $c_st_err;
  }
  .green {
    color: $c_st_ok;
  }
  .red {
    color: $c_st_err;
  }
  .base {
    color: $c_base;
  }
}

/* piece_load_popup */
.popup_wrap {
  background-color: transparentize($bg_popup, max(0, min(1, 1 - $bg_popup_trans)));
  display: none;
  font-size: rem(13);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  &.show {
    display: block;
  }
  .popup_cont {
    background-color: $bg_base;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    left: 50%;
    max-height: 92%;
    overflow: auto; //ie fix
    padding: rem(10) rem(16) rem(16) rem(16);
    position: absolute;
    top: 4%;
    transform: translateX(-50%);
    &.piece_zoomed_in {
      padding: 0;
      position: static;
      transform: none;
      visibility: hidden;
      .piece_cont {
        display: none;
      }
    }
  }
  .title {
    font-size: rem(14);
    padding: rem(4) 0;
  }
  .control_sep {
    min-height: rem(11);
    padding-bottom: rem(1);
    padding-top: rem(1);
    &:before { margin-top: 0; }
  }
  .control_cont {
    padding-bottom: rem(2);
    padding-top: rem(2);
    $inp_list: '.control_btn'; //, '.control_sel', '.control_color', '.my_controls';
    @each $inp1 in $inp_list {
      @each $inp2 in $inp_list {
        #{$inp1} + #{$inp2} {
          margin-left: rem(5);
        }
      }
    }
  }
  .control_btn {
    font-size: 1em;
  }
  .pieces_wrap {
    flex-grow: 1;
    min-height: rem(120);
    overflow: auto;
    .piece_cont {
      display: flex;
      padding: rem(2) rem(6);
      &:nth-child(odd) {
        background-color: $bg_stripe2;
      }
      &:nth-child(even) {
        background-color: $bg_stripe1;
      }
      &.piece_zoomed_in {
        box-sizing: border-box;
        display: flex;
        left: auto;
        max-height: 92%;
        max-width: 90%;
        padding: rem(20);
        position: fixed;
        top: 4%;
        visibility: visible;
        .piece_img_wrap {
          order: 5;
          padding-left: rem(6);
        }
        .piece_img_cb {
          display: none;
        }
        .piece_img_cont {
          height: auto;
          width: auto;
          img {
            display: block;
            left: auto;
            max-height: 100%;
            max-width: 100%;
            position: relative;
            top: auto;
            transform: none;
          }
        }
        .piece_zoom_cont {
          order: 0;
          padding-left: 0;
          .piece_zoom:after {
            content: 'Back';
          }
        }
        .piece_name_cont {
          order: 10;
        }
        .transp_colors {
          order: 15;
        }
      }
    }
    .piece_img_cb {
      cursor: pointer;
      margin: rem(10) rem(10) rem(10) rem(4);
    }
    .piece_img_wrap {
      align-items: center;
      cursor: pointer;
      //display: block;
      display: flex;
      padding-right: rem(6);
    }
    .piece_img_cont {
      $img_size: 120;
      display: block;
      height: rem($img_size);
      position: relative;
      width: rem($img_size);
      img {
        border: 1px solid $b_img;
        display: block;
        height: auto;
        left: 50%;
        max-height: rem($img_size);
        max-width: rem($img_size);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
      }
    }
    .piece_zoom_cont {
      padding-left: rem(6);
      padding-right: rem(6);
      .piece_zoom:after {
        content: 'Zoom';
      }
    }
    .piece_name_cont {
      flex-direction: column;
      justify-content: center;
      padding-left: rem(6);
      padding-right: rem(6);
      .piece_name {
        margin-top: rem(4);
        max-width: 7em;
        text-align: center;
      }
    }
    .transp_colors {
      flex-wrap: nowrap;
      justify-content: space-around;
      padding-bottom: 0;
      padding-top: 0;
      text-align: center;
      width: rem(220);
      .transp_color {
        padding-left: rem(6);
        padding-right: rem(6);
        > * {
          visibility: hidden;
        }
        &.enabled {
          > * {
            visibility: visible;
          }
        }
        label {
          cursor: pointer;
          display: block;
          text-align: center;
          visibility: inherit;
        }
      }
      .piece_trans_cb {
        cursor: pointer;
        margin: rem(4) rem(10) 0 rem(4);
      }
      .control_color {
        height: rem(21);
        margin-bottom: rem(12);
        margin-top: rem(4);
      }
      .control_range {
        margin-bottom: 0;
        margin-top: rem(4);
        width: rem(60);
      }
    }
  }

  $oload_osave_maxw: 500;
  $oload_osave_maxw_in: 440;
  $oload_osave_maxw_in_sm: 400;
  $oload_osave_minw: 440;
  .oload_wrap,
  .oload_loading,
  .oload_list_cont,
  .oload_key {
    margin: 0 auto;
    max-width: rem($oload_osave_maxw);
    min-width: rem($oload_osave_minw);
    text-align: center;
  }
  .oload_list_note {
    font-size: rem(10);
    max-width: rem($oload_osave_maxw_in);
    padding: rem(6) 0;
    text-align: left;
  }
  .oload_osave_loading_img {
    display: block;
    margin: rem(8) auto 0 auto;
  }
  .oload_loading {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: rem(88);
    padding: rem(10) 0;
    .oload_loading_in {
      text-align: center;
      width: 100%;
    }
  }
  .oload_list_cont {
    flex-grow: 1;
    min-height: rem(100);
    overflow: auto;
    padding: rem(8) 0 0 0;
    .oload_list_name {
      font-size: rem(12);
      max-width: rem($oload_osave_maxw_in);
    }
    .oload_list_out {
      max-height: rem(300);
      overflow: auto;
      padding: rem(2) 0;
      width: 100%;
    }
    .oload_list {
      border: 1px solid $b_table;
      margin: 0 auto;
      width: 100%;
      td, th {
        padding: rem(6) rem(4);
      }
      thead {
        border-bottom: 1px solid $b_table;
      }
      tbody {
        tr {
          &.puzzle {
            //cursor: pointer;
          }
          &:nth-child(odd) {
            background-color: $bg_stripe2;
          }
          &:nth-child(even) {
            background-color: $bg_stripe1;
          }
          &.sel {
            background-color: $bg_sel;
          }
        }
      }
      td {
        &.date {
          width: 12em;
        }
      }
      tr {
        &.removing {
          .link_cont {
            background: transparent url('#{$images_path}loading24.gif') center center no-repeat;
          }
          .link {
            visibility: hidden;
          }
        }
      }
    }
  }
  .oload_osave_key {
    font-size: rem(11);
    margin: 0 auto;
    max-width: rem($oload_osave_maxw_in_sm);
    min-width: 0;
    padding: rem(6) 0 0 0;
    .key_input,
    .key_btn {
      font-size: 1em;
      text-align: center;
      &:disabled {
        cursor: auto;
        cursor: not-allowed; // scss-lint:disable DuplicateProperty
      }
    }
    .key_btn {
      cursor: pointer;
    }
    .oload_load_key_cont {
      text-align: center;
    }
    .key_status {
      font-size: rem(11);
      max-width: 100%;
      min-height: 1em;
      padding: rem(5) 0 rem(3) 0;
      text-align: center;
      &.green {
        color: $c_st_ok;
      }
      &.red {
        color: $c_st_err;
      }
    }
  }
  .osave_wrap {
    align-items: start;
  }
  .osave_cont {
    padding: 0 rem(6) 0 0;
    text-align: left;
    .save_input {
      font-size: 1em;
      text-align: center;
      &:disabled {
        cursor: auto;
        cursor: not-allowed; // scss-lint:disable DuplicateProperty
      }
    }
    .name_status {
      font-size: rem(11);
      max-width: 100%;
      min-height: 1em;
      padding: rem(5) 0 rem(3) 0;
      text-align: center;
      &.green {
        color: $c_st_ok;
      }
      &.red {
        color: $c_st_err;
      }
    }
  }
  .osave_name_info {
    font-size: rem(11);
    max-width: rem($oload_osave_maxw_in);
    padding: rem(5) 0;
  }
}

/* right side */
.right_side_cont {
  margin: 0 0 0 rem(200 + 5 + 5);
  padding: 0 0 0 rem(8);
}

/* canvas_links_wrap */
.canvas_links_wrap {
  $name_lh: 14;
  font-size: rem(11);
  overflow: auto;
  max-width: 100%;
  padding: 0 0 rem(4) 0;
  .title {
    padding: 0 0 rem(2) 0;
  }
  .canvas_links_cont {
    padding: rem(2) 0 0 0;
    white-space: nowrap;
  }
  .link {
    border: 1px solid $b_table;
    display: inline-block;
    margin: 0 rem(4) 0 0;
    text-align: center;
    vertical-align: top;
    width: rem(80);
    white-space: normal;
    a {
      display: block;
      min-height: rem(82);
    }
    .img_cont {
      height: rem(60);
      margin: 0 0 rem(2) 0;
      padding: rem(2);
    }
    .img_cont_sm {
      height: rem(60 - $name_lh);
    }
    img {
      //border: 1px solid $b_table;
      display: block;
      height: auto;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: auto;
    }
    .name {
      display: block;
      margin: auto 0 0 0;
      padding: 0 0 rem(2) 0;
      line-height: rem($name_lh);
    }
  }
}

/* canvas */
.canvas_wrap {
  padding: rem(5) 0;
  .canvas_cont {
    background-color: $bg_puzzle;
    border: 1px solid $b_puzzle;
    position: relative;
    &:focus {
      outline: none;
    }
    &.loading {
      &:after {
        background: transparent url('#{$images_path}loading43.gif') center rem(50) no-repeat;
        content: ' ';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }
  canvas {
    display: block;
  }
}
.canvas_bnote,
.canvas_note {
  max-width: $canvas_size_w_min + px;
  > div {
    padding: rem(2) 0;
  }
}
.canvas_note {
  font-size: rem(11);
  padding: 0 0 rem(4) 0;
}
.canvas_bnote {
  $fsize: 11;
  $lh: 13;
  float: right;
  font-size: rem($fsize);
  line-height: lh($lh, $fsize);
  padding: rem(1) 0 0 0;
  text-align: right;
  &.left {
    float: left;
    padding-right: rem(8);
    text-align: left;
  }
}

//infos
.info_expand_wrap {
  .info_expand_open {
    .no_open_text {
      display: inline;
    }
    .open_text {
      display: none;
    }
  }
  .info_expand {
    display: none;
  }
  &.open {
    .info_expand_open {
      .no_open_text {
        display: none;
      }
      .open_text {
        display: inline;
      }
    }
    .info_expand {
      display: block;
    }
  }
}
.info_cont {
  text-align: left;
  > div {
    padding: rem(2) 0;
  }
  ul {
    list-style: disc outside;
    padding-left: 1.4em;
    li {
      padding: rem(2) 0 rem(1) 0;
    }
  }
  .sep {
    padding: rem(4) 0 rem(2) 0;
  }
}